import React from 'react'

const NotFound = () => {
    return (
        <div className="container text-center my-5">
            <h2>Beklager - Siden findes ikke!</h2>
        </div>
    )
}

export default NotFound
